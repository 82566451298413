
//PROD

export const environment = {
  production: true,
  CERBO_PATIENT_ENDPOINT: '/api/v1/patients',
  SF_GET_USER_OBJECT_ENDPOINT: '/services/data/v52.0/sobjects/',
  SF_ERROR_LOGS_ENDPOINT: '/services/data/v51.0/sobjects/',
  SF_SCHEDULE_CHANGE_ENDPOINT: '/services/data/v52.0/sobjects/',
  SF_SCHEDULE_CHANGE_ENDPOINT_COMP: '/services/data/v52.0/composite/sobjects/',
  SF_QUERY:'/services/data/v52.0/query/?q=',
  NODE_SERVICE_BASE_URL: "https://2xmdbvjqb1.execute-api.us-east-1.amazonaws.com/latest",
  NODE_SERVICE_API_END_POINT: "/api/rosewellness-service-express",
  SF_DOC_SIGN:'/services/apexrest/SendContract',
  IMAGE_PATH: '~/../assets/images/',
  CERBO_PATIENT_ADDRESS:'/services/apexrest/CerboPatientActionAPI',
  SF_CREATE_UPDATE_P_LICENSE: '/services/data/v51.0/sobjects/',
  SF_FILE_UPLOAD:'/services/data/v53.0/sobjects/ContentVersion',
  SF_FILE_GET:'/services/apexrest/FilesAPI',
  SF_FILE_STEP3: '/services/data/v53.0/sobjects/ContentDocumentLink',
  SF_QUERY_V56: '/services/data/v56.0/query?q=',
  SF_QUERY_V61: '/services/data/v61.0/query?q=',
  IS_AUTHENTICATE : true,
  ENABLE_SESSION_TRACK: true,
  IS_PROD: false
};

